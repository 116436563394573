import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ServerContext from '~src/serverContext';
import { useGet } from '~hooks/useQuery';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';
import { useListingRedirect } from '~hooks/useListingRedirect';

import Breadcrumb from '~components/breadcrumbs';
import Hero from '~components/hero';
import Loader from '~components/loader';

import './style.scss';

const Sitemap = () => {
  const intl = useIntl();
  const listingRedirect = useListingRedirect();
  const cache = useContext(ServerContext);
  useLayout({ displayTopCTABar: false, displaySignIn: true });
  useSeo(
    intl.formatMessage({
      id: 'containers.sitemap.seo.title',
    })
  );

  const [sortedCities, setSortedCities] = useState([]);
  const { data: cities, isLoading } = useGet({
    name: `cities`,
    params: {
      sitemap: true,
    },
    cacheData: cache.get('serverSitemapCities'),
    callback: (data) => {
      if (data && data.data) {
        setSortedCities(data.data.sort((a, b) => (a.city_slug > b.city_slug ? 1 : -1)));
      }
    },
  });

  return (
    <>
      <section className="c-subheader">
        <div className="l-container">
          <Breadcrumb.Wrapper>
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to={listingRedirect} index={1}>
                {intl.formatMessage({
                  id: 'breadcrumbs.home',
                })}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to={`/sitemap`} index={2}>
                {intl.formatMessage({
                  id: 'breadcrumbs.sitemap',
                })}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
          </Breadcrumb.Wrapper>
        </div>
      </section>
      <section className="c-page l-container">
        <Hero.Wrapper>
          <Hero.Title>
            {intl.formatMessage({
              id: 'containers.sitemap.headline',
            })}
          </Hero.Title>
          <Hero.Intro>
            {intl.formatMessage({
              id: 'containers.sitemap.introduction',
            })}
          </Hero.Intro>
        </Hero.Wrapper>

        <div className="c-sitemap l-page-content">
          <div className="c-faq__section">
            <div className="c-faq__question">
              {intl.formatMessage({
                id: 'containers.sitemap.pages.headline',
              })}
            </div>
            <div className="c-faq__response">
              <ul>
                <li className="c-sitemap__item">
                  <Link to="/">Homepage</Link>
                </li>
                <li className="c-sitemap__item">
                  <Link
                    to={`/${intl.formatMessage({
                      id: 'routes.faq',
                    })}`}
                  >
                    {intl.formatMessage({
                      id: 'breadcrumbs.faq',
                    })}
                  </Link>
                </li>
                <li className="c-sitemap__item">
                  <Link
                    to={`/${intl.formatMessage({
                      id: 'routes.contact',
                    })}`}
                  >
                    {intl.formatMessage({
                      id: 'breadcrumbs.contact',
                    })}
                  </Link>
                </li>
                <li className="c-sitemap__item">
                  <Link
                    to={`/${intl.formatMessage({
                      id: 'routes.privacy-policy',
                    })}`}
                  >
                    {intl.formatMessage({
                      id: 'breadcrumbs.privacy-policy',
                    })}
                  </Link>
                </li>
                <li className="c-sitemap__item">
                  <Link
                    to={`/${intl.formatMessage({
                      id: 'routes.terms-and-conditions',
                    })}`}
                  >
                    {intl.formatMessage({
                      id: 'breadcrumbs.terms-and-conditions',
                    })}
                  </Link>
                </li>
                <li className="c-sitemap__item">
                  <Link
                    to={`/${intl.formatMessage({
                      id: 'routes.cleaning-work',
                    })}`}
                  >
                    {intl.formatMessage({
                      id: 'breadcrumbs.cleaning-work',
                    })}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {isLoading ? (
            <div className="c-sitemap__loader">
              <Loader />
            </div>
          ) : null}
          {cities && cities.data && sortedCities ? (
            <div className="c-faq__section">
              <div className="c-faq__question">
                {intl.formatMessage({
                  id: 'containers.sitemap.cities.headline',
                })}
              </div>
              <div className="c-faq__response">
                <ul className="c-sitemap__list">
                  {sortedCities.map(({ city_name, city_slug }) => (
                    <li className="c-sitemap__item" key={`${city_name}-${city_slug}`}>
                      <Link to={`/${city_slug}`} className="c-vertical-nav__item">
                        {city_name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}

          <div className="l-center l-mt2">
            <Link to={listingRedirect} className="c-btn">
              {intl.formatMessage({
                id: 'containers.sitemap.back-to-homepage',
              })}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sitemap;
